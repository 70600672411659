<template>
  <div id="publicTable">
    <table class="public-table">
      <thead>
        <tr>
          <th>举报标题</th>
          <th>上传摘要</th>
          <th>处理状态</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in listArr" :key="index">
          <td>
            <router-link :to="{ path: 'submitInfo', query: { id: item.ID } }">
              <span v-text="item.Title"></span>
            </router-link>
          </td>
          <td>
            <router-link :to="{ path: 'submitInfo', query: { id: item.ID } }">
              <p v-if="type === 'vcList'">
                申请人：<span v-text="item.NickName"></span>
              </p>
              <p>污染类型：{{ item.TypeName }}</p>
              <p>申请时间：{{ item.AddTime | dateFormat }}</p>
            </router-link>
          </td>
          <td>
            <template v-if="type === 'vcList'">
              <router-link :to="{ path: 'submitInfo', query: { id: item.ID } }">
                <span style="color:#f46666;" v-if="item.State === 0"
                  >未验证</span
                >
                <span style="color:#6ad998;" v-if="item.State === 1"
                  >已验证</span
                >
              </router-link>
            </template>
            <template v-if="type === 'submitList'">
              <router-link :to="{ path: 'submitInfo', query: { id: item.ID } }">
                <span
                  style="color:#f2b064;"
                  v-if="
                    item.State === 0 || item.State === 1 || item.State === 2
                  "
                  >已提交平台</span
                >
                <span style="color:#f2b064;" v-if="item.State === 3"
                  >已提交政府</span
                >
                <span
                  style="color:#f2b064;display:block;"
                  v-if="item.State === 4"
                  >政府已处理</span
                >
                <span
                  style="color:#f2b064;display:block;"
                  v-if="item.State === 4"
                  >等待回访</span
                >
                <span
                  style="color:#f2b064;"
                  v-if="item.State === 5 || item.State === 7"
                  >已回访</span
                >
                <span style="color:#6ad998;" v-if="item.State === 6"
                  >已处理</span
                >
              </router-link>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "publicTable",
  props: {
    type: String,
    listArr: Array
  }
};
</script>

<style lang="less" scoped>
#publicTable {
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  .public-table {
    width: 100%;
    border-spacing: inherit;
    thead {
      tr {
        background-color: #e7f4ea;
      }
    }
    tr {
      th,
      td {
        width: 33.33%;
        text-align: center;
        span,
        p {
          color: #333;
        }
      }
      th {
        height: 52px;
        line-height: 52px;
        border-right: 1px solid #fff;
        font-size: 16px;
        &:nth-child(3) {
          border: none;
        }
      }
      td {
        padding: 6px 0;
        span,
        p {
          font-size: 14px;
        }
        &:nth-child(2) {
          text-align: left;
          p {
            width: 66%;
            margin: 0 auto;
          }
        }
      }
    }
    tbody {
      tr {
        &:nth-child(2n) {
          background-color: #f8fcf9;
        }
      }
    }
  }
}
</style>

<template>
  <div id="adminVc" v-cloak>
    <div class="top">
      <div class="left">
        <p class="line"></p>
        <img :src="user.img" />
        <p class="box">
          <span class="user-name">{{ user.name }}</span>
          <span v-if="user.role === 2" class="rouls">平台会员</span>
          <span v-else class="rouls">会员</span>
        </p>
      </div>
      <div class="right">
        <ul>
          <li>
            <p class="p1">{{ state.all }}</p>
            <p class="p2">全部</p>
          </li>
          <li>
            <p class="p1">{{ state.valid }}</p>
            <p class="p2">已验证</p>
          </li>
          <li>
            <p class="p1">{{ state.uvalid }}</p>
            <p class="p2">未验证</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="vc-list">
      <publicTable :listArr="messageArr" type="vcList" />
      <p v-if="isEmptyData" class="empty-data">暂无数据!</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import publicTable from "../../components/publicTable";
export default {
  data() {
    return {
      messageArr: [],
      state: {
        all: "",
        valid: "",
        uvalid: ""
      },
      isEmptyData: false
    };
  },
  computed: mapState(["user"]),
  components: {
    publicTable
  },
  mounted() {
    // 获取验证列表
    this.$http
      .get("/report/api/GetMUserList", {
        params: {
          account: this.$store.state.user.account,
          validstate: "all"
        }
      })
      .then(response => {
        if (response.data.state) {
          if (response.data.data.length > 0) {
            this.messageArr = response.data.data;
          } else {
            this.isEmptyData = true;
          }
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    //获取状态
    this.$http
      .get("report/api/GetPreCount", {
        params: {
          account: this.$store.state.user.account
        }
      })
      .then(response => {
        if (response.data.state) {
          let all = response.data.data.filter(m => {
            return (
              m.State === 4 || m.State === 5 || m.State === 6 || m.State === 7
            );
          });
          let valid = response.data.data.filter(m => {
            return m.State === 5 || m.State === 6 || m.State === 7;
          });
          let uvalid = response.data.data.filter(m => {
            return m.State === 4;
          });
          this.state.all = all.reduce((total, num) => {
            return total + num.Num;
          }, 0);
          this.state.valid = valid.reduce((total, num) => {
            return total + num.Num;
          }, 0);
          this.state.uvalid = uvalid.reduce((total, num) => {
            return total + num.Num;
          }, 0);
        } else {
          console.log(response.data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
};
</script>

<style lang="less" scoped>
#adminVc {
  width: 1200px;
  margin: 0 auto;
  .top {
    width: 100%;
    height: 150px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    margin: 18px 0;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    .left {
      width: 400px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      img {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        margin-top: 22px;
      }
      .line {
        position: absolute;
        right: 0;
        top: 50%;
        width: 1px;
        height: 64px;
        background-color: #eeeeee;
        transform: translateY(-50%);
      }
      .box {
        width: 100%;
        text-align: center;
        margin-top: -16px;
        .user-name {
          font-size: 18px;
          color: #333333;
        }
        .rouls {
          font-size: 12px;
          color: #fff;
          background-color: #cfa972;
          padding: 1px 5px;
          border-radius: 3px;
          margin-left: 8px;
          position: relative;
          top: -1px;
        }
      }
    }
    .right {
      height: 100%;
      ul {
        width: 600px;
        height: 100%;
        display: flex;
        align-items: center;
        li {
          width: 33%;
          height: 65px;
          p {
            width: 100%;
            text-align: center;
          }
          .p1 {
            font-size: 28px;
            color: #777777;
          }
          .p2 {
            font-size: 16px;
            color: #2c2824;
          }
          &:nth-child(1) {
            background: url("../../assets/20.png") no-repeat;
          }
          &:nth-child(2) {
            background: url("../../assets/21.png") no-repeat;
          }
          &:nth-child(3) {
            background: url("../../assets/22.png") no-repeat;
          }
        }
      }
    }
  }
  .vc-list {
    width: 100%;
    margin-bottom: 18px;
    overflow: hidden;
    .empty-data {
      width: 100%;
      line-height: 60px;
      text-align: center;
      background-color: #fff;
      font-size: 16px;
      color: #777777;
      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
    }
  }
}
</style>
